import { LoaderFunctionArgs } from "@remix-run/node"
import { typedjson, useTypedLoaderData } from "remix-typedjson"
import { twMerge } from "tailwind-merge"

import { prisma } from "~/db.server"
import LozengeButton, { type ButtonSize } from "~/designComponents/LozengeButton"
import CircleHead from "~/icons/CircleHead"
import ClipboardDocList from "~/icons/ClipboardDocList"
import FileBox from "~/icons/FileBox"
import FoldedMap from "~/icons/FoldedMap"
import Phone from "~/icons/Phone"
import PuzzlePiece from "~/icons/PuzzlePiece"
import { getPossiblyAuthenticatedUser } from "~/modules/auth/auth.server"

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await getPossiblyAuthenticatedUser(request)

  const communityOrgUsers = user
    ? await prisma.communityOrgUser.findMany({
        include: { communityOrg: true },
        where: { userId: user.id, removedAt: null },
      })
    : []

  return typedjson({ communityOrgUsers, user })
}

type LoaderFullData = Awaited<ReturnType<Awaited<ReturnType<typeof loader>>["typedjson"]>>
type LoaderData<K extends keyof LoaderFullData> = LoaderFullData[K]

function CallToActionButton({
  className,
  size = "xlarge",
  user,
}: {
  className?: string
  size?: ButtonSize
  user: LoaderData<"user">
}) {
  return (
    <LozengeButton
      className={twMerge("rounded-md bg-purple-600 font-bold text-white", className)}
      size={size}
      text={user ? "Go to Dashboard" : "Get Started"}
      toRoute={user ? "/dashboard" : "/join"}
    />
  )
}

export default function HomePage() {
  const { user } = useTypedLoaderData<typeof loader>()

  return (
    <>
      <header className="flex items-center justify-between border-b px-global-side-padding-small py-3 md:px-global-side-padding">
        <div className="flex flex-row items-center gap-x-2.5">
          <img
            alt="Bendigo Bank Logo"
            className="hidden h-6 md:block"
            src="/images/branding/bendigo-bank-logo.png"
          />
          <img
            alt="Bendigo Bank Logo"
            className="block h-4 md:hidden"
            src="/images/branding/bendigo-bank-logomark.png"
          />
        </div>

        <CallToActionButton className="bg-lime-700" size="medium" user={user} />
      </header>

      <div className="flex w-full flex-col items-center justify-center gap-y-10 pb-16 md:gap-y-20">
        <div className="relative flex w-full max-w-6xl flex-row px-global-side-padding-small md:mt-4 md:px-global-side-padding">
          <div
            className="absolute bottom-0 left-0 right-0 top-0 -z-10 bg-top md:hidden"
            style={{
              backgroundImage:
                "linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url(images/team.jpg)",
              backgroundRepeat: "no-repeat",
            }}
          />

          <div className="flex flex-col gap-y-6 py-16 pr-20 md:flex-1 md:pt-10">
            <h1 className="text-4xl font-bold text-sky-950">Run Your Community Group for Free.</h1>

            <p className="text-xl text-neutral-800">
              Make it easy to keep your club or group running. Try the Community Building Platform
              today! Free to use, simple to set up.
            </p>

            <CallToActionButton className="mt-6 self-start" size="2xlarge" user={user} />
          </div>

          <div
            className="hidden flex-1 rounded-md bg-center shadow-symm-heavy md:block"
            style={{
              backgroundImage: "url(images/team.jpg)",
              backgroundRepeat: "no-repeat",
            }}
          />
        </div>

        <div className="flex max-w-6xl flex-col items-center gap-y-8 px-global-side-padding-small md:gap-y-14 md:px-global-side-padding">
          <h2 className="max-w-4xl text-center text-3xl font-bold text-sky-950 md:text-4xl">
            You signed up to help out, not to do admin. Here&rsquo;s how we make it easier.
          </h2>

          <div className="flex flex-col gap-6 md:grid md:grid-cols-3">
            <div className="flex flex-col gap-y-4 rounded-lg bg-slate-100 p-4 md:p-6">
              <FileBox className="mb-2 h-16 w-16 self-center text-sky-900" />

              <h3 className="text-xl font-bold text-sky-950 md:text-2xl">Docs and Admin</h3>

              <p className="text-lg text-neutral-700 md:text-xl">
                We tell you what docs you need, create them from battle-tested templates, and store
                them.
              </p>
            </div>

            <div className="flex flex-col gap-y-4 rounded-lg bg-slate-100 p-4 md:p-6">
              <CircleHead className="mb-2 h-16 w-16 self-center text-sky-900" />

              <h3 className="text-xl font-bold text-sky-950 md:text-2xl">People</h3>

              <p className="text-lg text-neutral-700 md:text-xl">
                Manage your members and officers, make sure everyone knows what their role is.
              </p>
            </div>

            <div className="flex flex-col gap-y-4 rounded-lg bg-slate-100 p-4 md:p-6">
              <FoldedMap className="mb-2 h-16 w-16 self-center text-sky-900" />

              <h3 className="text-xl font-bold text-sky-950 md:text-2xl">Planning and Projects</h3>

              <p className="text-lg text-neutral-700 md:text-xl">
                We help you with your goals, we help you work out how to achieve them.
              </p>
            </div>

            <div className="flex flex-col gap-y-4 rounded-lg bg-slate-100 p-4 md:p-6">
              <ClipboardDocList className="mb-2 h-16 w-16 self-center text-sky-900" />

              <h3 className="text-xl font-bold text-sky-950 md:text-2xl">Communicating</h3>

              <p className="text-lg text-neutral-700 md:text-xl">
                Survey your members and find out what they&rsquo;re thinking, so you can make better
                decisions.
              </p>
            </div>

            <div className="flex flex-col gap-y-4 rounded-lg bg-slate-100 p-4 md:p-6">
              <PuzzlePiece className="mb-2 h-16 w-16 self-center text-sky-900" />

              <h3 className="text-xl font-bold text-sky-950 md:text-2xl">Experience</h3>

              <p className="text-lg text-neutral-700 md:text-xl">
                Using Bendigo&rsquo;s long history with communities, we help you put the puzzle
                together.
              </p>
            </div>

            <div className="flex flex-col gap-y-4 rounded-lg bg-slate-100 p-4 md:p-6">
              <Phone className="mb-2 h-16 w-16 self-center text-sky-900" />

              <h3 className="text-xl font-bold text-sky-950 md:text-2xl">Contacts</h3>

              <p className="text-lg text-neutral-700 md:text-xl">
                We give you people who solved your exact problem, supporting you with anything.
              </p>
            </div>
          </div>

          <CallToActionButton size="2xlarge" user={user} />
        </div>
      </div>

      <footer className="bg-sky-900 px-global-side-padding-small py-5 md:px-global-side-padding">
        <span className="text-sm text-white">© Copyright 2024 Bendigo and Adelaide Bank</span>
      </footer>
    </>
  )
}
